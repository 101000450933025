'use client';

import React from 'react';

import ErrorPage, { errorTemplates } from '@sbt-web/errors-page';

import '@sbt-web/errors-page/dist/index.css';

const FiveHundred = () => {
  const { title, image, description } = errorTemplates['500'];

  return <ErrorPage title={title} image={image} description={description} />;
};

export default FiveHundred;
